<template>
  <div class="settings columns container content-box">
    <setting-intro-banner
      :header-image="inbox.avatarUrl"
      :header-title="inboxName"
    >
      <woot-tabs
        class="settings--tabs"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :test-id="tab.testid"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
    </setting-intro-banner>

    <div v-if="selectedTabKey === 'inbox_settings'" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
      >
        <woot-avatar-uploader
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
          data-testid="chanel-avatar-uploader"
          :src="avatarUrl"
          class="settings-item"
          delete-avatar
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
        <woot-input
          v-model.trim="selectedInboxName"
          class="medium-9 columns settings-item"
          :class="{ error: $v.selectedInboxName.$error }"
          :label="inboxNameLabel"
          :placeholder="inboxNamePlaceHolder"
          :error="
            $v.selectedInboxName.$error
              ? $t('INBOX_MGMT.ADD.CHANNEL_NAME.ERROR')
              : ''
          "
          @blur="$v.selectedInboxName.$touch"
        />
        <label
          v-if="isATwitterInbox"
          for="toggle-business-hours"
          class="toggle-input-wrap"
        >
          <input
            v-model="tweetsEnabled"
            type="checkbox"
            name="toggle-business-hours"
          />
          {{ $t('INBOX_MGMT.ADD.TWITTER.TWEETS.ENABLE') }}
        </label>
        <woot-input
          v-if="isAPIInbox"
          v-model.trim="webhookUrl"
          class="medium-9 columns settings-item"
          :class="{ error: $v.webhookUrl.$error }"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL')
          "
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER')
          "
          :error="
            $v.webhookUrl.$error
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR')
              : ''
          "
          @blur="$v.webhookUrl.$touch"
        />
        <inbox-slug-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWebsiteUrl"
          class="medium-9 columns settings-item"
          :channel-id="inbox.channel_id"
          :show-copy-btn="true"
          @ok="slugError = false"
          @error="slugError = true"
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTitle"
          data-testid="inbox-settings-welcome-title"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
            )
          "
        />

        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTagline"
          v-tooltip="
            channelWelcomeTagline ||
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
              )
          "
          class="medium-9 columns settings-item"
          data-testid="inbox-settings-welcome-tagline"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
            )
          "
        />

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
          <woot-color-picker v-model="inbox.widget_color" />
        </label>

        <label v-if="isAWhatsAppChannel" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
          <input v-model="whatsAppAPIProviderName" type="text" disabled />
        </label>

        <label v-if="!isACallInbox" class="medium-9 columns settings-item">
          {{
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL')
          }}
          <select
            v-model="greetingEnabled"
            data-testid="inbox-channel-greeting-select"
          >
            <option :value="true">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
                )
              }}
            </option>
            <option :value="false">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
                )
              }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
              )
            }}
          </p>
        </label>
        <div v-if="greetingEnabled" class="settings-item">
          <p v-if="isACallInbox" class="margin-bottom-0 fw-medium fs-small">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
              )
            }}
          </p>
          <greetings-editor
            v-model.trim="greetingMessage"
            :label="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
              )
            "
            :placeholder="getDefaultGreetingPlaceholder"
            :richtext="!textAreaChannels"
          />
        </div>
        <label
          v-if="isAWebWidgetInbox && !isACallInbox"
          class="medium-9 columns settings-item"
        >
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
          <select v-model="replyTime" data-testid="inbox-set-reply-time-select">
            <option
              key="in_a_few_minutes"
              value="in_a_few_minutes"
              data-testid="inbox-set-time-minutes-option"
            >
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES')
              }}
            </option>
            <option
              key="in_a_few_hours"
              value="in_a_few_hours"
              data-testid="inbox-set-time-hours-option"
            >
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS')
              }}
            </option>
            <option
              key="in_a_day"
              value="in_a_day"
              data-testid="inbox-set-time-day-option"
            >
              {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY') }}
            </option>
          </select>

          <p class="help-text">
            {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT') }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
          <select
            v-if="!isTypebotConnectedToInbox"
            v-model="emailCollectEnabled"
            data-testid="inbox-enable-email-collect-select"
          >
            <option :value="true" data-testid="inbox-enabled-email-option">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}
            </option>
            <option :value="false" data-testid="inbox-disabled-email-option">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
            </option>
          </select>
          <select
            v-if="isTypebotConnectedToInbox"
            :disabled="true"
            data-testid="inbox-edit-email-collect-select"
          >
            <option
              :value="false"
              :selected="true"
              data-testid="inbox-edit-email-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
            </option>
          </select>
          <p v-if="isTypebotConnectedToInbox" class="collect-email-note">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.EMAIL_COLLECT_BOX_TYPEBOT_NOTE') }}
          </p>
          <p v-if="!isTypebotConnectedToInbox" class="help-text">
            {{
              $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT')
            }}
          </p>
        </label>

        <label v-if="!isACallInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT') }}
          <select
            v-model="csatSurveyEnabled"
            data-testid="inbox-enable-csat-select"
          >
            <option
              :value="true"
              data-testid="inbox-enable-csat-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-enable-csat-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT') }}
          </p>
        </label>

        <!-- TODO: Karen: Hide till we have SMS support -->
        <label
          v-if="false && isACallInbox && hasSMSCapability"
          class="medium-9 columns settings-item"
        >
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_SMS') }}
          <select v-model="smsEnabled" data-testid="inbox-enable-sms-select">
            <option :value="true" data-testid="inbox-enable-sms-enabled-option">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-enable-sms-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
            </option>
          </select>
        </label>
        <label v-if="!isACallInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.EDIT.ENABLE_AUTO_ASSIGN.TITLE') }}
          <select
            v-model="autoAssignEnabled"
            data-testid="inbox-enable-auto-assign-select"
          >
            <option
              :value="true"
              data-testid="inbox-enable-auto-assign-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_AUTO_ASSIGN.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-enable-auto-assign-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_AUTO_ASSIGN.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_AUTO_ASSIGN.DESCRIPTION') }}
          </p>
        </label>
        <label
          v-if="showConfigAllowMessagesAfterResolved"
          class="medium-9 columns settings-item"
        >
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED') }}
          <select
            v-model="allowMessagesAfterResolved"
            data-testid="inbox-allow-messages-after-select"
          >
            <option
              :value="true"
              data-testid="inbox-allow-messages-after-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-allow-messages-after-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGE_WHEN_USER_QUEUED') }}
          <select
            v-model="allowMessageWhenUserQueued"
            data-testid="inbox-allow-message-select"
            @change="handleAllowMessageWhenUserQueued"
          >
            <option
              value="enabled"
              data-testid="inbox-allow-message-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGE_WHEN_USER_QUEUED.ENABLED') }}
            </option>
            <option
              value="disabled"
              data-testid="inbox-allow-message-disabled-option"
            >
              {{
                $t('INBOX_MGMT.EDIT.ALLOW_MESSAGE_WHEN_USER_QUEUED.DISABLED')
              }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGE_WHEN_USER_QUEUED_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{
            $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_SHOWING_PREV_CONVERSATIONS')
          }}
          <select
            v-model="enableConversationHistory"
            data-testid="inbox-show-conversation-select"
            @change="handleShowConversationHistoryChange"
          >
            <option
              value="enabled"
              data-testid="inbox-show-conversation-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.SHOW_CONVERSATION_HISTORY.ENABLED') }}
            </option>
            <option
              value="disabled"
              data-testid="inbox-show-conversation-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.SHOW_CONVERSATION_HISTORY.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ENABLE_SHOWING_PREV_CONVERSATIONS_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL') }}
          <select
            v-model="continuityViaEmail"
            data-testid="inbox-email-conversation-continuity-select"
          >
            <option
              :value="true"
              data-testid="inbox-email-conversation-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-email-conversation-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT'
              )
            }}
          </p>
        </label>
        <div v-if="!isACallInbox" class="medium-9 settings-item settings-item">
          <label>
            {{ $t('INBOX_MGMT.HELP_CENTER.LABEL') }}
          </label>
          <select
            v-model="selectedPortalSlug"
            class="filter__question"
            data-testid="inbox-help-center-select"
          >
            <option value="" data-testid="inbox-help-center-placeholder-option">
              {{ $t('INBOX_MGMT.HELP_CENTER.PLACEHOLDER') }}
            </option>
            <option
              v-for="p in portals"
              :key="p.slug"
              :value="p.slug"
              :data-testid="`inbox-help-center-${p.slug}-option`"
            >
              {{ p.name }}
            </option>
          </select>
          <p class="help-text">
            {{ $t('INBOX_MGMT.HELP_CENTER.SUB_TEXT') }}
          </p>
        </div>
        <label
          v-if="canLocktoSingleConversation"
          class="medium-9 columns settings-item"
        >
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION') }}
          <select
            v-model="locktoSingleConversation"
            data-testid="inbox-single-conversation-select"
          >
            <option
              :value="true"
              data-testid="inbox-single-conversation-enabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.ENABLED') }}
            </option>
            <option
              :value="false"
              data-testid="inbox-single-conversation-disabled-option"
            >
              {{ $t('INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox">
          {{ $t('INBOX_MGMT.FEATURES.LABEL') }}
        </label>
        <div
          v-if="isAWebWidgetInbox"
          class="widget--feature-flag settings-item"
        >
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="attachments"
            @input="handleFeatureFlag"
          />
          <label for="attachments">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            id="emoji_picker"
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="emoji_picker"
            @input="handleFeatureFlag"
          />
          <label for="emoji_picker">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            id="send_audio"
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="send_audio"
            @input="handleFeatureFlag"
          />
          <label for="send_audio">
            {{ $t('INBOX_MGMT.FEATURES.ALLOW_SENDING_AUDIO') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            id="end_conversation"
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="end_conversation"
            @input="handleFeatureFlag"
          />
          <label for="end_conversation">
            {{ $t('INBOX_MGMT.FEATURES.ALLOW_END_CONVERSATION') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            id="hide_minimize_button"
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="hide_minimize_button"
            @input="handleFeatureFlag"
          />
          <label for="hide_minimize_button">
            {{ $t('INBOX_MGMT.FEATURES.HIDE_MINIMIZE_BUTTON') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            id="use_inbox_avatar_for_bot"
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="use_inbox_avatar_for_bot"
            @input="handleFeatureFlag"
          />
          <label for="use_inbox_avatar_for_bot">
            {{ $t('INBOX_MGMT.FEATURES.USE_INBOX_AVATAR_FOR_BOT') }}
          </label>
        </div>

        <woot-submit-button
          v-if="isAPIInbox"
          type="submit"
          :disabled="$v.webhookUrl.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdating"
          @click="updateInbox"
        />
        <woot-submit-button
          v-else
          v-tooltip="
            slugError ? $t('INBOX_MGMT.MYHOORY.UPDATE_BUTTON_ERROR') : ''
          "
          type="submit"
          :disabled="$v.$invalid || slugError"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdating"
          @click="updateInbox"
        />
      </settings-section>
      <facebook-reauthorize v-if="isAFacebookInbox" :inbox-id="inbox.id" />
    </div>

    <div v-if="selectedTabKey === 'phone_numbers'" class="settings--content">
      <phone-numbers-page :inbox="inbox" />
    </div>

    <div v-if="selectedTabKey === 'collaborators'" class="settings--content">
      <collaborators-page :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'configuration'">
      <configuration-page :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'preChatForm'">
      <pre-chat-form-settings :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'businesshours'">
      <weekly-availability :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'widgetBuilder'">
      <widget-builder :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'botConfiguration'">
      <bot-configuration :inbox="inbox" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shouldBeUrl } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize';
import PreChatFormSettings from './PreChatForm/Settings';
import WeeklyAvailability from './components/WeeklyAvailability';
import InboxSlugInput from 'components/InboxSlugInput.vue';
import GreetingsEditor from 'shared/components/GreetingsEditor';
import ConfigurationPage from './settingsPage/ConfigurationPage';
import CollaboratorsPage from './settingsPage/CollaboratorsPage';
import PhoneNumbersPage from './settingsPage/PhoneNumbersPage';
import WidgetBuilder from './WidgetBuilder';
import BotConfiguration from './components/BotConfiguration';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import enLanguage from '../../../../../dashboard/i18n/locale/en/inboxMgmt.json';

export default {
  components: {
    BotConfiguration,
    CollaboratorsPage,
    PhoneNumbersPage,
    ConfigurationPage,
    FacebookReauthorize,
    GreetingsEditor,
    PreChatFormSettings,
    SettingIntroBanner,
    InboxSlugInput,
    SettingsSection,
    WeeklyAvailability,
    WidgetBuilder,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      slugError: false,
      avatarFile: null,
      avatarUrl: '',
      greetingEnabled: true,
      tweetsEnabled: true,
      greetingMessage: '',
      emailCollectEnabled: false,
      csatSurveyEnabled: false,
      autoAssignEnabled: false,
      smsEnabled: false,
      locktoSingleConversation: false,
      allowMessagesAfterResolved: true,
      enableConversationHistory: 'enabled',
      allowMessageWhenUserQueued: 'enabled',
      prevConversation: true,
      continuityViaEmail: true,
      selectedInboxName: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      selectedTabIndex: 0,
      selectedPortalSlug: '',
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      uiFlags: 'inboxes/getUIFlags',
      portals: 'portals/allPortals',
      activeAgentBot: 'agentBots/getActiveAgentBot',
    }),
    getDefaultGreetingPlaceholder() {
      return this.isACallInbox
        ? this.getDefaultGreeting
        : this.$t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
          );
    },
    hasSMSCapability() {
      if (this.primaryNumber) {
        return this.primaryNumber?.capabilities.SMS;
      }
      return false;
    },
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    whatsAppAPIProviderName() {
      if (this.isAWhatsAppCloudChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD');
      }
      if (this.is360DialogWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG');
      }
      if (this.isATwilioWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO');
      }
      return '';
    },
    tabs() {
      let visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.SETTINGS'),
          testid: enLanguage.INBOX_MGMT.TABS.SETTINGS,
        },
      ];

      if (this.isACallInbox) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'phone_numbers',
            name: this.$t('INBOX_MGMT.TABS.PHONE_NUMBERS'),
            testid: enLanguage.INBOX_MGMT.TABS.PHONE_NUMBERS,
          },
        ];
      }

      visibleToAllChannelTabs = [
        ...visibleToAllChannelTabs,
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
          testid: enLanguage.INBOX_MGMT.TABS.COLLABORATORS,
        },
        {
          key: 'businesshours',
          name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
          testid: enLanguage.INBOX_MGMT.TABS.BUSINESS_HOURS,
        },
      ];

      if (this.isAWebWidgetInbox) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'preChatForm',
            name: this.$t('INBOX_MGMT.TABS.PRE_CHAT_FORM'),
            testid: enLanguage.INBOX_MGMT.TABS.PRE_CHAT_FORM,
          },
          {
            key: 'widgetBuilder',
            name: this.$t('INBOX_MGMT.TABS.WIDGET_BUILDER'),
            testid: enLanguage.INBOX_MGMT.TABS.WIDGET_BUILDER,
          },
        ];
      }

      if (
        this.isATwilioChannel ||
        this.isALineChannel ||
        this.isACallInbox ||
        this.isAPIInbox ||
        (this.isAnEmailChannel && !this.inbox.provider) ||
        (this.isAnEmailChannel && this.inbox.provider === 'microsoft') ||
        this.isAWhatsAppChannel ||
        this.isAWebWidgetInbox
      ) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
            testid: enLanguage.INBOX_MGMT.TABS.CONFIGURATION,
          },
        ];
      }

      if (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.AGENT_BOTS
        ) &&
        !(this.isAnEmailChannel || this.isATwitterInbox)
      ) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'botConfiguration',
            name: this.$t('INBOX_MGMT.TABS.BOT_CONFIGURATION'),
            testid: enLanguage.INBOX_MGMT.TABS.BOT_CONFIGURATION,
          },
        ];
      }
      return visibleToAllChannelTabs;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxName() {
      if (this.isATwilioSMSChannel || this.isATwilioWhatsAppChannel) {
        return `${this.inbox.name} (${this.inbox.messaging_service_sid ||
          this.inbox.phone_number})`;
      }
      if (this.isAWhatsAppChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isAnEmailChannel) {
        return `${this.inbox.name} (${this.inbox.email})`;
      }
      return this.inbox.name;
    },
    canLocktoSingleConversation() {
      return this.isASmsInbox || this.isAWhatsAppChannel;
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    },
    isTypebotConnectedToInbox() {
      const agentBot = this.activeAgentBot(this.inbox.id);
      return agentBot?.description === '___typebot___';
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
    inbox() {
      if (this.inbox?.id) {
        this.$store.dispatch('agentBots/get');
        this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
    this.fetchPortals();
  },
  methods: {
    handleShowConversationHistoryChange(e) {
      if (e.target.value === 'enabled') {
        this.selectedFeatureFlags = [
          ...this.selectedFeatureFlags,
          'show_conversation_history',
        ];
      } else {
        this.selectedFeatureFlags = this.selectedFeatureFlags.filter(
          flag => flag !== 'show_conversation_history'
        );
      }
    },
    handleAllowMessageWhenUserQueued(e) {
      if (e.target.value === 'enabled') {
        this.selectedFeatureFlags = [
          ...this.selectedFeatureFlags,
          'allow_message_when_user_queued',
        ];
      } else {
        this.selectedFeatureFlags = this.selectedFeatureFlags.filter(
          flag => flag !== 'allow_message_when_user_queued'
        );
      }
    },
    fetchPortals() {
      this.$store.dispatch('portals/index');
    },
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value
      );
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      if (!selectedTabIndex) {
        this.fetchInboxSettings();
      }
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchInboxSettings() {
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.avatarUrl = this.inbox.avatar_url;
        this.selectedInboxName = this.inbox.name;
        this.webhookUrl = this.inbox.webhook_url;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.enableConversationHistory = this.selectedFeatureFlags.includes(
          'show_conversation_history'
        )
          ? 'enabled'
          : 'disabled';
        this.allowMessageWhenUserQueued = this.selectedFeatureFlags.includes(
          'allow_message_when_user_queued'
        )
          ? 'enabled'
          : 'disabled';
        this.greetingEnabled = this.inbox.greeting_enabled || false;
        this.tweetsEnabled = this.inbox.tweets_enabled || false;
        this.greetingMessage = this.inbox.greeting_message || '';
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.csatSurveyEnabled = this.inbox.csat_survey_enabled;
        this.autoAssignEnabled = this.inbox.auto_assign;
        this.smsEnabled = this.inbox.sms_enabled;
        this.allowMessagesAfterResolved = this.inbox.allow_messages_after_resolved;
        this.continuityViaEmail = this.inbox.continuity_via_email;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.replyTime = this.inbox.reply_time;
        this.locktoSingleConversation = this.inbox.lock_to_single_conversation;
        this.selectedPortalSlug = this.inbox.help_center
          ? this.inbox.help_center.slug
          : '';
      });
    },
    async updateInbox() {
      if (this.isACallInbox && this.greetingMessage === '') {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.REQUIRED'
          )
        );
      } else {
        if (this.slugError) return;
        try {
          const payload = {
            id: this.currentInboxId,
            name: this.selectedInboxName,
            enable_email_collect: this.emailCollectEnabled,
            csat_survey_enabled: this.csatSurveyEnabled,
            auto_assign: this.autoAssignEnabled,
            // sms_enabled: this.smsEnabled,  //need BE support
            allow_messages_after_resolved: this.allowMessagesAfterResolved,
            greeting_enabled: this.greetingEnabled,
            greeting_message: this.greetingMessage || '',
            portal_id: this.selectedPortalSlug
              ? this.portals.find(
                  portal => portal.slug === this.selectedPortalSlug
                ).id
              : null,
            lock_to_single_conversation: this.locktoSingleConversation,
            channel: {
              widget_color: this.inbox.widget_color,
              website_url: this.channelWebsiteUrl,
              webhook_url: this.webhookUrl,
              welcome_title: this.channelWelcomeTitle || '',
              welcome_tagline: this.channelWelcomeTagline || '',
              selectedFeatureFlags: this.selectedFeatureFlags,
              reply_time: this.replyTime || 'in_a_few_minutes',
              tweets_enabled: this.tweetsEnabled,
              continuity_via_email: this.continuityViaEmail,
            },
          };
          if (this.avatarFile) {
            payload.avatar = this.avatarFile;
          }
          await this.$store.dispatch('inboxes/updateInbox', payload);
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(
            error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
          );
        }
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
  },
  validations: {
    webhookUrl: {
      shouldBeUrl,
    },
    selectedInboxName: {},
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
  background: $color-white;
  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.settings-item {
  padding-bottom: var(--space-normal);

  .help-text {
    font-style: normal;
    color: var(--b-500);
    padding-bottom: var(--space-smaller);
  }
}

.collect-email-note {
  font-size: var(--font-size-mini);
  color: var(--y-500);
}
</style>
